import { VariantElement } from 'types/app'

const inputDefaultClasses =
  'bg-white rounded-lg py-3 font-semibold text-base placeholder:text-light-gray-400 disabled:text-light-gray-300'

const labelDefaultClasses = 'font-semibold text-xs mb-1.5 w-max'

const helperDefaultClasses = 'font-semibold text-xs mt-1.5'

export const InputVariant: VariantElement = {
  primary: `${inputDefaultClasses} text-primary-300 hover:border-primary-400 border-primary-300 focus-visible:outline-primary-300`,
  secondary: `${inputDefaultClasses} text-success-400 hover:border-success-500 border-success-400 focus-visible:outline-success-400`,
  error: `${inputDefaultClasses} text-danger-400 hover:border-danger-500 border-danger-400 focus-visible:outline-danger-400`,
  default: `${inputDefaultClasses} text-dark-gray-500 hover:border-light-gray-500 border-light-gray-400 focus-visible:outline-dark-gray-400`,
}

export const GridVariant: VariantElement = {
  primary: `text-primary-300 border-primary-300 hover:border-primary-400`,
  secondary: `text-success-400 border-success-400 hover:border-success-500`,
  error: `text-danger-400 border-danger-400 hover:border-danger-500`,
  default: `text-dark-gray-500 border-light-gray-400 hover:border-light-gray-500`,
}

export const GridFocusVariant: VariantElement = {
  primary: `text-primary-300 border-2 border-primary-300`,
  secondary: `text-success-400 border-2 border-success-400`,
  error: `text-danger-400 border-2 border-danger-400`,
  default: `text-dark-gray-500 border-2 border-dark-gray-400`,
}

export const HelperTextVariant: VariantElement = {
  primary: `${helperDefaultClasses} text-primary-300`,
  secondary: `${helperDefaultClasses} text-success-400`,
  error: `${helperDefaultClasses} text-danger-400`,
  default: `${helperDefaultClasses} text-dark-gray-500`,
}

export const LabelVariant: VariantElement = {
  primary: `${labelDefaultClasses} text-primary-300`,
  secondary: `${labelDefaultClasses} text-success-400`,
  error: `${labelDefaultClasses} text-danger-400`,
  default: `${labelDefaultClasses} text-dark-gray-500`,
}
