import { useTranslator } from '@components/Core/Translator/index.hook'
import HeadNext from 'next/head'
import { FC, useEffect, useState } from 'react'

type HeadProps = {
  title: string
}

export const Head: FC<HeadProps> = ({ title }) => {
  const { language, t: translate } = useTranslator()
  const [pageTitle, setPageTitle] = useState('Braile ...')

  useEffect(() => {
    setPageTitle(`Braile | ${translate(title).toString()}`)
  }, [title, language])

  return (
    <HeadNext>
      <title>{pageTitle}</title>
    </HeadNext>
  )
}
