import { FC, PropsWithChildren, HTMLProps, Children } from 'react'
import { Variant } from 'types/app'
import { Adornment } from 'types/elements'
import { Flex } from '../Display'
import { Translator } from '../Translator'
import { ButtonVariant } from './variants'

type ButtonType = 'button' | 'submit'

type ButtonProps =
  | HTMLProps<HTMLButtonElement> & {
      startAdornment?: Adornment
      endAdornment?: Adornment
      variant?: Variant
      path?: string
    }

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  startAdornment,
  endAdornment,
  variant = 'primary',
  path,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`${ButtonVariant[variant]} ${props.className}`}
      type={props.type ? (props.type as ButtonType) : 'button'}
    >
      <Flex className="gap-3 justify-center items-center">
        <>
          {startAdornment && (
            <div {...startAdornment?.props}>{startAdornment?.element}</div>
          )}
          {Children.count(children) ? (
            children
          ) : (
            <Translator path={path ?? ''} />
          )}
          {endAdornment && (
            <div {...endAdornment?.props}>{endAdornment?.element}</div>
          )}
        </>
      </Flex>
    </button>
  )
}
