import { VariantElement } from 'types/app'

const buttonDefault = 'rounded-3xl py-3 px-6 text-base font-semibold'

export const ButtonVariant: VariantElement = {
  primary: `${buttonDefault} hover:bg-primary-400 active:bg-primary-500 bg-primary-300 text-white fill-white disabled:opacity-75 disabled:bg-light-gray-200`,
  secondary: `${buttonDefault} hover:bg-secondary-400 active:bg-secondary-500 bg-secondary-300 text-white fill-white disabled:opacity-75 disabled:bg-light-gray-200`,
  error: buttonDefault,
  default: `${buttonDefault} border border-light-gray-400 active:text-primary-400 active:fill-primary-400 hover:border-light-gray-500 bg-white text-primary-300 fill-primary-300 disabled:text-light-gray-300 disabled:hover:border-light-gray-400`,
}
