import {
  FC,
  Ref,
  HTMLProps,
  InputHTMLAttributes,
  useCallback,
  ChangeEvent,
} from 'react'
import { TestProperties, Variant } from 'types/app'
import { Adornment } from 'types/elements'
import * as LabelPrimitive from '@radix-ui/react-label'
import { useTranslator } from '@components/Core/Translator/index.hook'
import { Flex, Grid } from '@components/Core/Display'
import { Translator } from '@components/Core/Translator'
import { HelperTextVariant, LabelVariant } from './variants'
import { InputHook } from './index.hook'

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  helperText?: string | boolean
  containerProps?: HTMLProps<HTMLDivElement> & TestProperties
  labelProps?:
    | (LabelPrimitive.LabelProps & Ref<HTMLSpanElement>)
    | TestProperties
  helperProps?: HTMLProps<HTMLSpanElement> & TestProperties
  label?: string
  startAdornment?: Adornment
  endAdornment?: Adornment
  variant?: Variant
}

export const Input: FC<InputProps> = ({
  containerProps,
  labelProps,
  label,
  name,
  startAdornment,
  endAdornment,
  helperText,
  variant,
  helperProps,
  ...props
}) => {
  const { handleActiveFocus, classes } = InputHook({
    startAdornment,
    endAdornment,
    variant,
  })
  const { t: translate } = useTranslator()

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (props.type === 'number') {
        const newEvent = { ...e }
        newEvent.target.value = newEvent.target.value.replace(/\D+/, '')

        props?.onChange && props.onChange(newEvent)
        return
      }

      props?.onChange && props?.onChange(e)
    },
    [props.type, props.onChange]
  )

  return (
    <Flex
      {...containerProps}
      className={`flex-col ${containerProps?.className ?? ''}`}
    >
      {label && (
        <LabelPrimitive.Root
          className={`${LabelVariant[variant ?? 'default']} ${
            labelProps?.className ?? ''
          }`}
          htmlFor={name}
          {...labelProps}
        >
          {props.required && <span className="text-danger-500">*</span>}
          <Translator path={label} />
        </LabelPrimitive.Root>
      )}

      <Grid className={`${classes.gridClass} ${props.className ?? ''}`}>
        {startAdornment && (
          <div {...startAdornment.props}>{startAdornment.element}</div>
        )}
        <input
          {...props}
          placeholder={translate(props.placeholder ?? '')}
          onChange={handleChange}
          type={props.type === 'number' ? 'text' : props.type}
          onFocus={(e) => handleActiveFocus(true, e, props.onFocus)}
          onBlur={(e) => {
            handleActiveFocus(false, e, props.onFocus)
            if (!props.onBlur) return
            props.onBlur(e)
          }}
          className={`${classes.inputClass} ${props.className ?? ''}`}
          name={name}
          required={false}
        />
        {endAdornment && (
          <div {...endAdornment.props}>{endAdornment.element}</div>
        )}
      </Grid>

      {helperText && (
        <span
          {...helperProps}
          className={`${HelperTextVariant[variant ?? 'default']} ${
            helperProps?.className ?? ''
          }`}
        >
          <Translator path={String(helperText)} />
        </span>
      )}
    </Flex>
  )
}

Input.defaultProps = {
  containerProps: undefined,
  labelProps: undefined,
  label: undefined,
  startAdornment: undefined,
  endAdornment: undefined,
  helperText: undefined,
  variant: 'default',
  helperProps: undefined,
}
