import { languageAtom } from '@states/atoms/language'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { Language } from 'types/language'

export const useLanguageQuery = () => {
  const router = useRouter()
  const [language, setLanguage] = useAtom(languageAtom)
  const { lang } = useMemo(() => router.query, [router.query])

  useEffect(() => {
    if (!lang) return
    setLanguage(lang as Language)
  }, [lang])

  return {
    language,
  }
}
