import { Flex } from '@components/Core/Display'
import Image from 'next/image'
import { FC, PropsWithChildren } from 'react'

type LayoutProps = {
  image: string
}

export const AuthLayout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  image,
}) => {
  return (
    <div className="w-screen h-screen flex-col flex items-center lg:grid lg:grid-cols-auth-layout grid-cols-1">
      <div className="mb-8 lg:pl-8 lg:mb-0 mt-6 lg:mt-0 lg:top-19px w-full flex items-center lg:justify-start justify-center relative lg:absolute">
        <div className="w-32 h-7 lg:w-auth-logo relative lg:h-auth-logo">
          <Image layout="fill" src="/images/logo.png" />
        </div>
      </div>
      <Flex className="w-auto h-auto lg:h-screen justify-center items-center mx-6 lg:mx-14 ">
        {children}
      </Flex>
      <div className="w-auth-layout h-screen hidden lg:block relative">
        <Image layout="fill" priority src={image} />
      </div>
    </div>
  )
}
